import React from 'react'
import styles from './style.module.scss'
import { LogoImage } from '@Root/assets/icons'
import { Container } from '@Root/Components/Container'

export const DesktopHeader = ({ links, baseUrl }) => {
	return (
		<Container>
			<div className={styles.grid}>
				<a href={baseUrl}>
					<img src={LogoImage} alt='' className={styles.logo} />
				</a>
				<nav className={styles.navigation}>
					{links.map(({ label, value },i) => (
						<a className={styles.link} key={i} href={`${baseUrl}${value}`}>
							{label}
						</a>
					))}
				</nav>
			</div>
		</Container>
	)
}
