import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { outcomeBapOptions, fundYourCourseStudyOptions } from '@Root/configs'

export const MinisterialDetails = ({ onChange, data, errors = {}, isMobile, options, onFindDiocese, onFindDioceseById, fetchLabelHandler }) => {
	const {
		name_of_current_church,
		sending_diocese,
		name_of_your_DDO,
		date_of_last_meeting_with_DDO,
		email_contact_for_your_DDO,
		phone_contact_fo_your_DDO,
		date_of_bishops_advisory_panel,
		outcome_of_BAP,
		fund_your_course_study,
	} = data

	return (
		<div className={styles.section}>
			<SectionTitle>5. Financial Details</SectionTitle>
			<div className={styles.fields}>
				{/* <p>(If you are not applying for ordination in the Church of England, please move straight on to section 6)</p>
				<Field
					label={'Name of current church'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
					value={name_of_current_church}
					onChange={onChange('name_of_current_church')}
					maxLength={100}
					fieldClass={'long'}
				/>{' '}
				<Field
					label={'Sending diocese'}
					field={'asyncSelect'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '96%' : '287px' }}
					value={sending_diocese}
					onChange={onChange('sending_diocese')}
					options={options.dioceses}
					fieldClass={'long'}
					maxVisibleOptionsQuantity={5}
					fetchOptionsHandler={onFindDiocese}
					changeHandler={onFindDioceseById}
					fetchLabelHandler={fetchLabelHandler}
				/>{' '}
				<Field
					label={'Name of your DDO'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
					value={name_of_your_DDO}
					onChange={onChange('name_of_your_DDO')}
					maxLength={100}
					fieldClass={'long'}
				/>{' '}
				<Field
					label={'Date of last meeting with DDO'}
					field={'datePicker'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '288px' }}
					value={date_of_last_meeting_with_DDO}
					onChange={onChange('date_of_last_meeting_with_DDO')}
					maxLength={100}
					fieldClass={'long'}
				/>
				<div id={errors.email_contact_for_your_DDO ? 'scrollHere' : null}>
					<Field
						label={'Email contact for your DDO'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
						value={email_contact_for_your_DDO}
						onChange={onChange('email_contact_for_your_DDO')}
						maxlength={100}
						error={errors.email_contact_for_your_DDO}
						fieldClass={'long'}
					/>
				</div>
				<Field
					label={'Phone contact for your DDO'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '268px' }}
					value={phone_contact_fo_your_DDO}
					onChange={onChange('phone_contact_fo_your_DDO')}
					maxlength={100}
					fieldClass={'long'}
					restriction={'digits'}
				/>
				<Field
					label={'Date of Bishops Advisory Panel'}
					field={'datePicker'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100% ' : '288px' }}
					value={date_of_bishops_advisory_panel}
					onChange={onChange('date_of_bishops_advisory_panel')}
					fieldClass={'long'}
				/>*/}
				{/* <Field
					label={'Outcome of BAP'}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '170px' }}
					value={outcome_of_BAP}
					options={outcomeBapOptions}
					onChange={onChange('outcome_of_BAP')}
					fieldClass={'single'}
					isInline
				/> */}

				<div style={{ width: '578px' }}>
					<Field
						label={'Please indicate how you plan to fund your course of study:'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '240px' }}
						value={fund_your_course_study}
						onChange={onChange('fund_your_course_study')}
						options={fundYourCourseStudyOptions}
						fieldClass={'single'}
						overrideStrings={{
							selectSomeItems: 'Select one or more course of study...',
						}}
						disableSearch
						field={'multiSelect'}
					/>
				</div>
				{fund_your_course_study.length !== 0 && (
					<div data-test-id='multi-select-option'>
						<div className={styles.selected_programme_title}>Selected course of study: </div>
						{fundYourCourseStudyOptions.map((option, i) => {
							return (
								fund_your_course_study.some((selected) => selected === option.value) && (
									<div key={i} className={styles.selected_programme_item}>
										{option.label}
									</div>
								)
							)
						})}
					</div>
				)}
			</div>
		</div>
	)
}
