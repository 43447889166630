import React from 'react'
import styles from './style.module.scss'
import { HamburgerImage, LogoImage } from '@Root/assets/icons'
import { useToggle } from '@Root/hooks'

export const MobileHeader = ({ links, baseUrl }) => {
	const [isOpen, toggleOpen] = useToggle()
	return (
		<div className={styles.grid}>
			<a href={baseUrl}>
				<img src={LogoImage} alt='' className={styles.logo} />
			</a>
			<div className={styles.menu}>
				<div className={styles.main} onClick={toggleOpen}>
					<p>Go to...</p>
					<div className={styles.hamburger}>
						<img src={HamburgerImage} alt='' />
					</div>
				</div>
				<nav className={`${styles.navigation} ${isOpen ? styles.open : ''}`}>
					{links.map(({ label, value },i) => (
						<a href={`${baseUrl}${value}`} key={i} className={styles.link}>
							{label}
						</a>
					))}
				</nav>
			</div>
		</div>
	)
}
