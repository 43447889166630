import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { booleanOptions } from '@Root/configs'

export const UserCircumstances = ({ onChange, data, isMobile }) => {
	const { additional_needs, additional_needs_description } = data
	return (
		<div className={styles.section}>
			<SectionTitle>7. Learning Support</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={'Do you have a specific learning difference, access requirement or other support requirement?'}
					options={booleanOptions}
					field={'radioInput'}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					value={additional_needs ? 1 : 2}
					onChange={(value) => onChange('additional_needs')(value === 1)}
					isInline
					fieldClass={'single'}
				/>{' '}
				<Field
					label={'If so, please provide details including any formal diagnosis. Disclosing this information does not impact the admissions decision:'}
					field={'textArea'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', height: '90px', width: isMobile ? 'calc(100% - 20px)' : '96%' }}
					value={additional_needs_description}
					onChange={onChange('additional_needs_description')}
					isInline
					fieldClass={'single'}
				/>
			</div>
		</div>
	)
}
