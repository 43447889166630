import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { educationQualificationOptions, professionalQualificationsOptions, recentEmploymentOptions } from '@Root/configs'

export const EducationAndEmployment = ({ onChange, data, onAddRow, onRemoveRow }) => {
	const { educational_qualifications = [], recent_employment_history = [], professional_qualifications = [] } = data
	return (
		<div className={styles.section}>
			<SectionTitle>4. Education and Employment</SectionTitle>
			<div className={styles.fields}>
				<Field
					label={'Educational qualifications:'}
					options={educationQualificationOptions}
					field={'formTable'}
					rows={educational_qualifications}
					onAddRow={onAddRow('educational_qualifications')}
					onRemoveBtn={onRemoveRow('educational_qualifications')}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					onChange={onChange('educational_qualifications')}
					fieldClass={'single'}
				/>{' '}
				<p>
					NB: <span style={{ color: '#9B9B9B' }}>Please list in date order, most recent first</span>
				</p>
				<Field
					label={'Professional qualifications:'}
					options={professionalQualificationsOptions}
					field={'formTable'}
					rows={professional_qualifications}
					onAddRow={onAddRow('professional_qualifications')}
					onRemoveBtn={onRemoveRow('professional_qualifications')}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					onChange={onChange('professional_qualifications')}
					fieldClass={'single'}
				/>
				<Field
					label={'Recent employment history:'}
					options={recentEmploymentOptions}
					field={'formTable'}
					rows={recent_employment_history}
					onAddRow={onAddRow('recent_employment_history')}
					onRemoveBtn={onRemoveRow('recent_employment_history')}
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: '150px' }}
					onChange={onChange('recent_employment_history')}
					fieldClass={'single'}
				/>
				<p>
					NB:{' '}
					<span style={{ color: '#9B9B9B' }}>
						Please list in date order, most recent first and please be prepared to provide copies of any certificates for academic and professional
						qualifications
					</span>
				</p>
			</div>
		</div>
	)
}
