import React from 'react'
import styles from './style.module.scss'
import { DesktopHeader } from './Desktop'
import { MobileHeader } from './Mobile'

const links = [
	{ label: 'About Us', value: 'about' },
	{ label: 'Undergraduate', value: 'undergraduate' },
	{ label: 'Postgraduate', value: 'postgraduate' },
	{ label: 'Online', value: 'online' },
	{ label: 'Research', value: 'research' },
	{ label: 'FAQ', value: 'faq' },
	{ label: 'Our Members', value: 'member-institutions' },
	{ label: 'Contact', value: 'contact' },
]
export const Header = () => {
	const BASE_URL = 'https://www.theofed.cam.ac.uk/'
	return (
		<header className={styles.header}>
			<DesktopHeader baseUrl={BASE_URL} links={links} />
			<MobileHeader baseUrl={BASE_URL} links={links} />
		</header>
	)
}
