import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'

export const UserStory = ({ onChange, data, isMobile }) => {
	const { story } = data
	return (
		<div className={styles.section}>
			<SectionTitle>6. Supporting statement</SectionTitle>
			<div className={`${styles.fields} ${styles.story}`}>
				<p>
					Please use this space, within 5000 characters, to tell us more about yourself: <br /> • Your ministry and mission involvements <br />• Your spiritual
					journey <br />• Your interests and passions <br />• Your possible future ministry
				</p>
				<Field
					hideLabel
					label={''}
					field={'textArea'}
					//Note: 20px in calc - right and left padding of textarea
					style={{ height: 293, width: isMobile ? 'calc(100% - 20px)' : '530px' }}
					value={story}
					onChange={onChange('story')}
					fieldClass={'long'}
					maxLength={5000}
				/>
			</div>
		</div>
	)
}
