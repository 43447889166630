import React from 'react'
import styles from './style.module.scss'
import { SectionTitle } from '@Root/Components/Titles'
import { Field } from '@Root/Components/Field'
import { titlesOptions } from '@Root/configs'

export const PersonalInformation = ({ onChange, data, errors = {}, isMobile, options = {} }) => {
	const {
		title,
		firstname,
		surname,
		previous_surname,
		sex_id,
		known_as,
		address,
		city,
		postcode,
		DOB,
		nationality_type_id,
		email,
		mobile_phone,
	} = data
	return (
		<div className={styles.section}>
			<SectionTitle>1. Personal Information</SectionTitle>
			<div className={styles.fields}>
					<Field
						label={'Title'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={title}
						onChange={onChange('title')}
						isInline
						error={errors.title}
						maxLength={100}
						fieldClass={'main'}
					/>{' '}
				<div className={styles.row}>
					<Field
						label={'Firstname(s)'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={firstname}
						onChange={onChange('firstname')}
						isInline
						error={errors.firstname}
						maxLength={100}
						isRequired
						fieldClass={'main'}
					/>{' '}
					<Field
						label={'Surname'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={surname}
						onChange={onChange('surname')}
						error={errors.surname}
						maxLength={100}
						isRequired
						fieldClass={'main'}
					/>
				</div>
				<div className={styles.row}>
					<Field
						label={'Previous surname'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={previous_surname}
						onChange={onChange('previous_surname')}
						maxLength={100}
						fieldClass={'main'}
					/>{' '}
					<Field
						label={'Gender'}
						field={'select'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '170px' }}
						value={sex_id}
						onChange={onChange('sex_id')}
						isInline
						error={errors.sex_id}
						options={options.genders}
						maxLength={100}
						optionsStyle={{ width: isMobile ? '96%' : '170px' }}
						isRequired
						fieldClass={'main'}
					/>
				</div>
				<div className={styles.row}>
					<Field
						label={'Known as'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={known_as}
						onChange={onChange('known_as')}
						maxlength={255}
						fieldClass={'main'}
					/>{' '}
				</div>
				<Field
					label={'Address'}
					field={'textField'}
					//Note: 20px - right and left padding of the field
					style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '447px' }}
					value={address}
					onChange={onChange('address')}
					maxlength={100}
					fieldClass={'main'}
				/>
				<div className={styles.row}>
					<Field
						label={'City'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={city}
						onChange={onChange('city')}
						maxlength={100}
						fieldClass={'main'}
					/>{' '}
					<Field
						label={'Postcode'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={postcode}
						onChange={onChange('postcode')}
						maxLength={100}
						fieldClass={'main'}
					/>
				</div>
				<div className={styles.row} id={!errors.DOB ? 'scrollHere' : null}>
					<Field
						label={'DOB'}
						field={'datePicker'}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '170px' }}
						value={DOB}
						onChange={onChange('DOB')}
						error={errors.DOB}
						fieldClass={'main'}
						isRequired
					/>{' '}
					<Field
						label={'Nationality'}
						field={'select'}
						options={options.nationalities}
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? '100%' : '170px' }}
						optionsStyle={{ width: isMobile ? '96%' : '170px' }}
						value={nationality_type_id}
						onChange={onChange('nationality_type_id')}
						fieldClass={'main'}
						error={errors.nationality_type_id}
						isRequired
					/>
				</div>
				<div className={styles.row} id={!errors.DOB ? 'scrollHere' : null}>
					<Field
						label={'Email'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={email}
						onChange={onChange('email')}
						error={errors.email}
						maxLength={100}
						isRequired
						fieldClass={'main'}
					/>
					<Field
						label={'Phone'}
						field={'textField'}
						//Note: 20px - right and left padding of the field
						style={{ color: '#9B9B9B', fontWeight: 'bold', width: isMobile ? 'calc(100% - 20px)' : '150px' }}
						value={mobile_phone}
						onChange={onChange('mobile_phone')}
						error={errors.mobile_phone}
						isRequired
						restriction='digits'
						maxlength={100}
						fieldClass={'main'}
					/>
				</div>
			</div>
		</div>
	)
}
