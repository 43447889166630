import React from 'react'
import styles from './style.module.scss'
import { ARULogoImage, CambridgeLogoImage, DurhamLogoImage, TwitterImage, WhiteLogoImage } from '@Root/assets/icons'
import { Container } from '@Root/Components/Container'

export const Footer = () => {
	return (
		<footer className={styles.footer}>
			<Container>
				<div className={styles.grid}>
					<div className={styles.column}>
						<img src={WhiteLogoImage} alt='CTF' />
						<address className={styles.content}>
							2 The Bounds,
							<br /> Westminster College,
							<br /> Lady Margaret Road,
							<br /> Cambridge, CB3 0BJ
						</address>
					</div>
					<div className={styles.column}>
						<p className={styles.content}>
							CTF is a Charitable Company,
							<br /> Limited by Guarantee.
						</p>{' '}
						<p className={styles.content}>
							Charity Registration 1099953,
							<br /> Company Number 4700056
						</p>
					</div>
					<div className={styles.column}>
						<a className={`${styles.content} ${styles.link}`} target='_blank'>
							Policies and Procedures
						</a>
						<a href='https://twitter.com/CamTheoFed' target='_blank'>
							<img src={TwitterImage} alt='' className={styles.twitter} />
						</a>
					</div>
					<div className={styles.column}>
						<p className={styles.content}>OUR PARTNERS</p>
						<a className={styles.partner} href='https://aru.ac.uk/' target='_blank'>
							<img src={ARULogoImage} alt='ARU' />
						</a>{' '}
						<a className={styles.partner} href='https://www.cam.ac.uk/' target='_blank'>
							<img src={CambridgeLogoImage} alt='Cambridge' />
						</a>{' '}
						<a className={styles.partner} href='https://www.durham.ac.uk/' target='_blank'>
							<img src={DurhamLogoImage} alt='Durham' />
						</a>
					</div>
				</div>
			</Container>
		</footer>
	)
}
